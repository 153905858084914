import accordion from "./components/accordion"
import header from "./components/header"
import menu from "./components/menu"
import outline from "./components/outline"
import scrollHint from "./components/scrollHint"
import stickylinks from "./components/stickylinks"
import video from "./components/video"
import toTop from "./components/toTop"

document.addEventListener(
  'DOMContentLoaded',
  () => {
    header()
    menu()
    stickylinks()
    outline()
    scrollHint()
    accordion()
    video()
    toTop()
  },
  false
)
