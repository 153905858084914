/**
 * Initialize scroll hint
 */
//@ts-nocheck
const scrollHint = () => {
  new ScrollHint('.js-scrollable', {
    suggestiveShadow: false,
    i18n: {
      scrollable: 'スクロールできます'
    }
  });
}

export default scrollHint