

const video = () => {

  const modalButton = document.querySelectorAll('.js-modal-video')

  if (modalButton) {
    if (modalButton.length > 0) {
      modalButton.forEach((item) => {
        item.addEventListener('click', (event) => {
          event.preventDefault()

          const modal = item.nextElementSibling

          if (modal) {
            const iframe = modal.querySelector('iframe')

            const itemElement = item as HTMLElement

            if (iframe) {
              iframe.src =
                'https://www.youtube.com/embed/' +
                itemElement.getAttribute('data-src') +
                '?autoplay=1'
            }

            const close = document.querySelectorAll('.js-close')

            modal.classList.add('active')

            if (close) {
              close.forEach((closeItem) => {
                closeItem.addEventListener('click', (e) => {
                  e.preventDefault()
                  modal.classList.remove('active')
                  document.documentElement.className = ''

                  if (iframe) {
                    iframe.src = ''
                  }
                })
              })
            }
          }
        })
      })
    }
  }
}

export default video;