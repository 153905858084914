// footer back to top

const backToTop = () => {
  const backToTop = document.querySelector('.js-back-to-top');
  const offset = 500;

  window.addEventListener('scroll', () => {
    const scrollY = window.scrollY;
    (scrollY > offset) ? backToTop?.classList.add('show') : backToTop?.classList.remove('show');
  });

  backToTop?.addEventListener('click', (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  });
}

export default backToTop;