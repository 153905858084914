/**
 * Toggle header menu
 */
const menu = () => {
  const toggle = document.querySelector('.js-menu-toggle');
  const hamburger = document.querySelector('.js-hamburger');

  // Toggle sidemenu on hamburger click
  toggle?.addEventListener('click', () => {
    toggle.classList.toggle('active');
    hamburger?.classList.toggle('active'); 
  });

  // Hide sidemenu when items other than .js-sidemenu and .js-hamburger-wrap are clicked
  document.querySelector('body')!.addEventListener('click', (e) => {
    if (e.target instanceof HTMLElement) {
      if (!e.target.closest('.js-menu-toggle') && !e.target.closest('.js-hamburger')) {
        toggle!.classList.remove('active');
        hamburger!.classList.remove('active');
      }
    }
  });
}

export default menu;