/**
 * Add data attribute to .title-outline
 */
const outline = () => {
  const items = document.querySelectorAll('.title-outline')

  if(items) {
    items.forEach(item=>{
      const txt = item.innerHTML
      item.setAttribute('data-outline-text', txt)
    })
  }
}

export default outline