/**
 * Toggle accordion
 */
const accordion = () => {
  const accordions = document.querySelectorAll('.js-accordion')

  if(accordions) {
    accordions.forEach(accordion=>{
      const toggle = accordion.querySelector('.js-accordion-toggle')
      const content = accordion.querySelector('.js-accordion-content')
      const wrap = content?.firstElementChild as HTMLElement
      const maxHeight = wrap.offsetHeight + 550;

      toggle?.addEventListener('click', function() {
        accordion.classList.toggle('active')
        if(content?.hasAttribute('style')) {
          content?.removeAttribute('style')
        } else {
          content?.setAttribute('style', 'max-height: '+maxHeight+'px;')
        }
      })
    })
  }
}

export default accordion