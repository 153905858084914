/**
 * Fixed button
 */

const stickylinks = () => {
  const container = document.querySelector('.js-stickylinks') as HTMLElement;

  if(container) {
    const button = document.querySelector('.js-stickylinks-toggle')

    button?.addEventListener('click', function() {
      container.classList.toggle('hidden')
    })

    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        container.classList.remove('hidden')
      } else {
        container.classList.add('hidden')
      }
      prevScrollpos = currentScrollPos;
    }
  }
}

export default stickylinks;
