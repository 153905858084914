/**
 * Toggle header on scroll
 */
const header = () => {
  const header = document.querySelector('header')

  window.addEventListener('scroll', () => {
    if(window.pageYOffset > 1) {
      header?.classList.add('scrolled')
    } else{
      header?.classList.remove('scrolled')
    }
  })
}

export default header